<template>
  <v-container>
    <Breadcrumb :data="breadcrumb" />
    <section class="my-8">
      <v-row align-md="center" align-lg="end">
        <v-col cols="12" md="4" class="d-none d-md-block pa-0 relative">
          <div class="triangle"></div>
          <v-img :aspect-ratio="9 / 16" src="@/assets/png/cement-bg2.png" lazy-src="@/assets/png/cement-bg2.png"
            max-height="575"></v-img>
        </v-col>
        <v-col cols="12" md="8" class="pa-0">
          <v-card class="pa-6 shadow rounded-0">
            <h5 class="text-h5 font-weight-bold text--black my-2">
              {{ $t("About us") }}
            </h5>
            <p class="font-weight-normal text--black my-2"
              v-html="($vuetify.rtl ? $store.state.website_basic_data.about_ar : $store.state.website_basic_data.about_en) || '-'" />
            <h5 class="text-h5 font-weight-bold text--black my-2">
              {{ $t("Missions") }}
            </h5>
            <p class="font-weight-normal text--black my-2"
              v-html="($vuetify.rtl ? $store.state.website_basic_data.mission_ar : $store.state.website_basic_data.mission_en) || '-'" />
            <h5 class="text-h5 font-weight-bold text--black my-2">{{ $t("Visions") }}</h5>
            <p class="font-weight-normal text--black my-2"
              v-html="($vuetify.rtl ? $store.state.website_basic_data.vision_ar : $store.state.website_basic_data.vision_en) || '-'" />
            <h5 class="text-h5 font-weight-bold text--black my-2">{{ $t("Goals") }}</h5>
            <p class="font-weight-normal text--black my-2"
              v-html="($vuetify.rtl ? $store.state.website_basic_data.goal_ar : $store.state.website_basic_data.goal_en) || '-'" />
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "AboutUs",
  components: {
    Breadcrumb,
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
  }),
  computed: {},
  methods: {
    xxx(){
      navigator.bluetooth.requestDevice({
        filters: [{ services: ['printer_service'] }]
      })
      .then(device => {
        console.log('Connected to printer:', device);
      })
      .catch(error => console.log('Bluetooth connection failed:', error));
    },
    getData() {
      this.xxx();
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$i18n.t("cement egypt society"),
          disabled: false,
          to: "/society",
        },
        {
          text: this.$i18n.t("about"),
          disabled: false,
          to: "/society/about",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.triangle {
  width: 70.1%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -70%;
  background: #1e1e20;
  -webkit-clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
  clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
}

.v-application--is-rtl {
  .triangle {
    left: -70%;
    right: unset;
    -webkit-clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  }
}
</style>
