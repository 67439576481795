<template>
  <v-card elevation="0" class="rounded-lg pa-4 mt-4">
    <div class="d-flex align-center justify-space-between" @click="collapseTable = !collapseTable">
      <div class="subtitle-1 font-weight-medium">{{ $t(headerTitle) }}</div>
      <!-- <div class="subtitle-2 primary--text text-decoration-underline cursor_pointer" v-if="!hideViewMore"
        @click="$router.push('/materials/' + $i18n.t(title).replaceAll('_', '-') + '/' + index)">
        {{ $t("see more") }}
      </div> -->
      <v-btn v-if="comparison == false"  icon>
        <v-icon v-if="collapseTable">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="subtitle-1 font-weight-bold" :class="data.length > 0 ? '' : 'error--text'">{{
      data.length > 0 ?
      $t(title) : $i18n.t('no prices available in') + ' ' + $t(title) | capitalize
    }}</div>
    <v-expand-transition>
      <div class="col-12 px-0 py-1 tableCol" v-if="data.length > 0 && collapseTable">
        <table class="materials-prices-table relative overflow-hidden">
          <thead>
            <!-- header -->
            <tr class="text-center">
              <th v-if="comparison == true">{{ $t("date") | capitalize }}</th>
              <th v-if="comparison == false">{{ $t("Producer") | capitalize }}</th>
              <th v-if="showType == true">{{ $t("product type") | capitalize }}</th>
              <th v-if="comparison == false">{{ $t("Product") | capitalize }}</th>
              <!-- <th v-if="comparison == false">{{ $t("governorate") | capitalize }}</th> -->
              <th v-if="comparison == false">{{ $t("date") | capitalize }}</th>
              <th class="font-weight-bold">{{ $t("Highest Price") | capitalize }}</th>
              <th class="font-weight-bold">{{ $t("Lowest Price") | capitalize }}</th>
              <th v-if="comparison == false">{{ $t("Today avg") | capitalize }}</th>
              <th v-if="comparison == false">{{ $t("Yesterday avg") | capitalize }}</th>
              <th>{{ $t("Change") | capitalize }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- row -->
            <tr class="text-center" v-for="(row, index) in data" :key="index">
              <td v-if="comparison == true">{{ row.price_date || '-' }}</td>
              <td v-if="comparison == false">{{ row.producer_name || '-' | capitalize }}</td>
              <td v-if="showType == true && comparison == false">{{ row.product_type_name || '-' | capitalize }}</td>
              <td v-if="comparison == false">{{ row.item_name || '-' | capitalize }}</td>
              <!-- <td v-if="comparison == false">{{ row.governorate_name || '-' | capitalize }}</td> -->
              <td v-if="comparison == false">{{ row.price_date || '-' | capitalize }}</td>
              <td class="font-weight-bold">
                <span v-if="row.max_price">{{ row.max_price | float }}</span>
                <span v-else class="caption font-weight-bold">{{ $t('not available') }}</span>
              </td>
              <td class="font-weight-bold">
                <span v-if="row.min_price">{{ row.min_price | float }}</span>
                <span v-else class="caption font-weight-bold">{{ $t('not available') }}</span>
              </td>
              
              <td v-if="comparison == false">
                <span v-if="row.mid_price">{{ row.mid_price || 0 | float }}</span>
                <span v-else class="caption font-weight-bold">{{ $t('not available') }}</span>
              </td>
              <td v-if="comparison == false">
                <span v-if="row.mid_price_yesterday"> {{ row.mid_price_yesterday || 0 | float }}</span>
                <span v-else class="caption font-weight-bold">{{ $t('not available') }}</span>
              </td>
              <td class="font-weight-bold" :class="(row.max_price - row.min_price) > 0 ? 'success--text' : 'error--text'">
                <span v-if="row.max_price && row.min_price">{{ row.max_price - row.min_price || 0 | float }}</span>
                <span v-else class="caption font-weight-bold">{{ $t('not available') }}</span>
              </td>
            </tr>
          </tbody>
          <div class="water-mark"></div>
        </table>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "MaterialPriceTable",
  props: {
    // backValueMethod: { type: Function },
    isLoading: { default: false },
    comparison: { default: false },
    data: { default: [] },
    title: { default: "" },
    objectKey: { default: "prices" },
    headerTitle: { default: "Materials prices" },
    index: { default: 0 },
    hideViewMore: { default: false },
    showGovernorate: { default: false },
    showType: { default: false },
  },
  components: {},
  data: () => ({
    collapseTable: true,
  }),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tableCol {
  @media screen and (max-width: 720px) {
    overflow-x: auto;
  }
}

.materials-prices-table {
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;

  @media screen and (max-width: 720px) {
    width: 1000px;
  }

  border: 0.704431px solid #c4c4c4;

  thead {
    th {
      border: 0;
      line-height: 3rem;
    }
  }

  tbody {
    tr {
      border: 0;
      border-color: transparent;

      &:nth-of-type(even) {
        background: #fff;
      }

      &:nth-of-type(odd) {
        background: rgba(0, 0, 0, 0.05);
      }

      td {
        border: 0;
        line-height: 3rem;
      }
    }
  }
}

.water-mark {
  position: absolute;
  width: 100%;
  height: 320%;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: url("../assets/logo.png");
  background-repeat: repeat;
  transform: rotate(45deg) scale(1.5, 1.5);
  opacity: 0.07;
}
</style>
