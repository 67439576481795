<template>
  <v-container fluid class="mt-3">
    <!-- Page -->
    <v-container fluid>
      <v-row v-if="!isLoading">
        <!-- Material prices -->
        <v-col cols="12" lg="4" sm="12" order="2" order-lg="1">
          <v-row justify="space-between">
            <v-col class="pa-3" cols="12" lg="12" sm="6">
              <MaterialsPrices :product_prices="product_prices" />
            </v-col>
            <v-col class="pa-3" cols="12" lg="12" sm="6">
              <ADImg :ad="getAD('01-007')" :maxHeight="200" :maxWidth="'auto'" :contain="false" :aspectRatio="1280/400" classes="mt-2 rounded-lg" />
            </v-col>
          </v-row>
        </v-col>
        <!-- End Material prices -->
        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- News Carousel -->
        <v-col cols="12" lg="8" sm="12" order="1" order-lg="2" v-if="slider_data.length > 0">
          <NewsCarousel :slider_data="slider_data" :isLoading="isLoading" />
        </v-col>
        <!-- End News Carousel -->
        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- ads -->
        <v-col cols="12" sm="12" order="3" order-lg="3">
          <ADImg :ad="getAD('01-008')" :maxHeight="300" :maxWidth="'auto'" :contain="false" :aspectRatio="1280/400" />
        </v-col>
        <!-- End ads -->
        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- News -->
        <v-col cols="12" sm="12" order="4" order-lg="4" v-if="general_news.length > 0">
          <NewsRow :isLoading="isLoading" :data="general_news" title="general news"  :link="'news/1/1/'+$t('general news')" />
        </v-col>
        <v-col cols="12" sm="12" order="4" order-lg="4" v-if="cement_news.length > 0">
          <NewsRow :isLoading="isLoading" :data="cement_news" title="cement news" :reverse="true" :link="'news/2/1/'+$t('cement news')" />
        </v-col>
        <v-col cols="12" sm="12" order="4" order-lg="4" v-if="cement_news_around_world.length > 0">
          <NewsRow :isLoading="isLoading" :data="cement_news_around_world" title="cement news around world" :link="'news/3/1/'+$t('cement news around world')" />
        </v-col>
        <!-- End News -->
        <!-- --------------------------------------------------------------------------------------------- -->

        <v-col cols="12" order="5" order-lg="6">
          <v-row>
            <!-- Statistics -->
            <v-col cols="12" v-if="statistics.length">
              <Statistics :isLoading="false" :data="statistics" title="Cement" />
            </v-col>
            <!-- ads -->
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="12" md="4" lg="3">
                  <v-row align="center">
                    <v-col cols="6" sm="4" md="6" lg="12">
                      <ADImg :ad="getAD('01-009')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                    <v-col cols="6" sm="4" md="6" lg="12">
                      <ADImg :ad="getAD('01-010')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                    <v-col cols="12" sm="4" md="12" lg="12">
                      <ADImg :ad="getAD('01-011')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="6">
                  <ADImg :ad="getAD('01-012')" :maxHeight="460" :maxWidth="'auto'" :contain="true" :aspectRatio="640/340" classes="mt-1 rounded-lg" />
                </v-col>
                <v-col cols="12" md="4" lg="3">
                  <v-row align="center">
                    <v-col cols="6" sm="4" md="6" lg="12">
                      <ADImg :ad="getAD('01-013')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                    <v-col cols="6" sm="4" md="6" lg="12">
                      <ADImg :ad="getAD('01-014')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                    <v-col cols="12" sm="4" md="12" lg="12">
                      <ADImg :ad="getAD('01-015')" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280/400" classes="mt-1 rounded-lg" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!-- Move Around Services -->
            <v-col cols="12">
              <MoveAroundServices :isLoading="false" :data="services" title="Move Around Services" />
            </v-col>
          </v-row>
        </v-col>

        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- Materials prices Tables -->
        <!-- <v-col cols="12" lg="8" sm="12" order="5" order-lg="6">
          <MaterialPriceTable :isLoading="isLoading" v-for="(tableData, index) in Object.keys(prices_tables)"
            :key="index" :data="prices_tables[tableData]" :index="index" :objectKey="Object.keys(prices_tables)[index]"
            :title="Object.keys(prices_tables)[index].replaceAll('_price', '') | capitalize" />
        </v-col> -->
        <!-- End Materials prices Tables -->
      </v-row>
      <v-row justify="center" v-if="isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import MaterialsPrices from "@/components/MaterialsPrices.vue";
import NewsCarousel from "@/components/NewsCarousel.vue";
import NewsRow from "@/components/NewsRow.vue";
import Statistics from "@/components/Statistics.vue";
// import MaterialPriceTable from "@/components/MaterialPriceTable.vue";
import MoveAroundServices from "@/components/MoveAroundServices.vue";
import ADImg from "@/components/ADImg.vue";

export default {
  name: 'Home',
  data: () => ({
    isLoading: false,
    cement_news: [],
    cement_news_around_world: [],
    general_news: [],
    slider_data: [],
    prices_tables: [],
    product_prices: {
      cement_price: { max_price: 0, min_price: 0, cement_kilo: 0 },
      cement_price2: { max_price: 0, min_price: 0, cement_kilo: 0 },
      cement_price3: { max_price: 0, min_price: 0, cement_kilo: 0 },
      cement_price4: { max_price: 0, min_price: 0, cement_kilo: 0 },
      iron_price: { max_price: 0, min_price: 0, iron_kilo: 0 },
      gypsum_prices: { max_price: 0, min_price: 0, gypsum_kilo: 0 },
      transfer_prices: { max_price: 0, min_price: 0, transfer_kilo: 0 },
    },
    home_ads: [],
    firstAd: {},
    secondAd: {},
    thirdAd: {},
    statistics: [],
    services: [
      {
        id: 1,
        country: 'KSA',
        image: 'cement-ksa.svg',
      },
      {
        id: 2,
        country: 'UEA',
        image: 'cement-uea.svg',
      },
      {
        id: 3,
        country: 'Oman',
        image: 'cement-oman.svg',
      },
      {
        id: 4,
        country: 'Qatar',
        image: 'cement-qatar.svg',
      },
      {
        id: 5,
        country: 'Kuwait',
        image: 'cement-uea.svg',
      }
    ]
  }),
  computed:{
    getAD(){
      return(adCode)=>{
        return this.home_ads.find((AD)=> AD.ads_code == adCode) || null
      }
    }
  },
  components: {
    MaterialsPrices,
    NewsCarousel,
    NewsRow,
    Statistics,
    // MaterialPriceTable,
    MoveAroundServices,
    ADImg
  },
  mounted() {
    this.$global.ADD_META('https://cementegypt.cs-testing.com/img/logo.65d6e91b.png', 'image');
    this.$global.ADD_META(['اسمنت, أسمنت، اخبار, حديد، جبس, اسعار, سعر, تحليل, حركة, صناعة, صفقات, صفقة, بيع, بائع, شراء, مشتري, خرسانة, مقاولون, استشاريون, وكلاء, وكيل, مشروعات, مشروع, موزعون, موزع, اتجاة, مستقر, هابط, صاعد, وظائف, الاسمنت المصري, الاسمنت حول العالم, cement, news', this.$i18n.t('Cement Egypt News')], 'keywords');
    this.$global.ADD_META(this.$i18n.t("Cement Egypt 'is the first website' specialized in the cement industry and the various economic industries related to it and seeks to support and this industry and all parties dealing with it"));
    this.getData()
  },
  methods: {
    
    getData() {
      this.isLoading = true;
      
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(`home`).then((response) => {
        this.isLoading = false
        if (response.check) {
          console.log(response);
          this.cement_news = response.data.cement_news || [];
          this.cement_news_around_world = response.data.cement_news_around_world || [];
          this.general_news = response.data.general_news || [];
          this.slider_data = response.data.all_active_sliders || [];
          this.home_ads = response.data.home_ads || [];
          this.prices_tables = response.data.prices_tables || [];
          this.product_prices = response.data.product_prices || [];
          this.statistics = response.data.govs_direction || [];
          this.firstAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-007')[0];
          this.secondAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-008')[0];
          this.thirdAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-009')[0];
          // 7 ads
        } else {
          this.cement_news = [];
          this.cement_news_around_world = [];
          this.general_news = [];
          this.slider_data = [];
          this.home_ads = [];
          this.prices_tables = [];
          this.product_prices = {
            cement_price: { max_price: 0, min_price: 0, cement_kilo: 0 },
            cement_price2: { max_price: 0, min_price: 0, cement_kilo: 0 },
            cement_price3: { max_price: 0, min_price: 0, cement_kilo: 0 },
            cement_price4: { max_price: 0, min_price: 0, cement_kilo: 0 },
            iron_price: { max_price: 0, min_price: 0, iron_kilo: 0 },
            gypsum_prices: { max_price: 0, min_price: 0, gypsum_kilo: 0 },
            transfer_prices: { max_price: 0, min_price: 0, transfer_kilo: 0 },
          };
        }
      })
    }
  }
  
}
</script>
